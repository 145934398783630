import "@nike/eds/dist/index.css";
import React, {useContext} from "react";
import {Route, Routes} from 'react-router-dom'
import {RequestDetail} from "./components/detail/RequestDetail";
import {AppsyncService} from "./components/shared/AppsyncService";
import {oktaAuth} from './components/config/OktaConfig'
import {StructuredLogsService} from "./components/shared/StructuredLogsService";
import ApplicationSnackbar from "./components/shared/ApplicationSnackbar";
import SnackbarContext from "./providers/snackbar-context";
import {FlowSearchOverview} from "./components/search/FlowSearchOverview";

const appsyncService: AppsyncService = new AppsyncService(oktaAuth)
const structuredLogsService: StructuredLogsService = new StructuredLogsService(appsyncService)

export function App() {
    const snackbarCtx = useContext(SnackbarContext);
    return (
        <div>
            <Routes>
                <Route path="/flow-overview" element={<FlowSearchOverview structuredLogsService={structuredLogsService}/>} />
                <Route path="/flow-overview/details/:traceId" element={<RequestDetail structuredLogsService={structuredLogsService}/>} />
            </Routes>
            {snackbarCtx.isDisplayed && <ApplicationSnackbar />}
        </div>
    );
}

export default App;
