import React, {useContext, useState} from "react";
import SnackbarContext from "../../providers/snackbar-context";
import {Box, IconButton, Text} from "@nike/eds";
import useSessionStorage from "../../hooks/useSessionStorage";
import {FlowSearchResults} from "./FlowSearchResults";
import {FlowSearchForm} from "./FlowSearchForm";
import {FlowSearchModel} from "../../model/FlowSearchModel";
import {edsSpace16} from "@nike/eds/dist/tokens";

export function FlowSearchOverview(props) {

    const structuredLogsService = props.structuredLogsService;
    const snackbarCtx = useContext(SnackbarContext);
    const [loading, setLoading] = useState(false);
    const [currentSearchForm, setCurrentSearchForm] = useState({});
    let [searchResults, setSearchResults] = useState([]);
    const [ pagingTokensSession, setPagingTokens ] = useSessionStorage("pagingTokens", []);
    let pagingTokens = pagingTokensSession.value;

    const searchHandler = (flowSearchModel: FlowSearchModel, restore : boolean) => {
        setCurrentSearchForm(flowSearchModel)
        // TODO setState is async > how do we fix this so it works like now without accessing the variable directly
        if (restore) {
            pagingTokens = pagingTokens.slice(0, -1);
        } else {
            pagingTokens = []
        }
        searchResults = []
        fetchData(flowSearchModel, 'down', restore)
    }

    const fetchData = (flowSearchModel: FlowSearchModel, direction: string, restore : boolean = false) => {
        setLoading(true);

        console.log(flowSearchModel);
        if (direction === 'up') {
            // remove next and current tokens from the pagingTokens array to retrieve previous set
            pagingTokens.pop()
            pagingTokens.pop()
        }

        structuredLogsService.listFlows(pagingTokens.at(-1), flowSearchModel.type, flowSearchModel.status, 
            flowSearchModel.startDate, flowSearchModel.endDate, flowSearchModel.businessReference,
            flowSearchModel.flowId)
        .then(result => {
            setLoading(false);
            setSearchResults(result.data.listFlowsDynamicSearch.items)
            if (!restore) {
                setPagingTokens(pagingTokens.concat(result.data.listFlowsDynamicSearch.nextToken))
            }
        })
        .catch(error => {
            setLoading(false);
            snackbarCtx.displayMsg(error.message, "error");
        });

    }

    const titleStyle = {
        marginBottom: edsSpace16
    }

    return (
        <div>
            <Text style={titleStyle} font={"display-3"} as={"h3"}>EVENTS</Text>

            <Box className="nesg-grid nesg-grid-md-cols-2 nesg-grid-items-center">
                <FlowSearchForm onSearch={searchHandler}/>
                <span className="eds-spacing--p-8" style={{display: 'flex', alignItems:'center', justifyContent: 'center'}}>
                    <IconButton icon="CaretLeft" variant="ghost" size="small" onClick={() => { searchHandler(currentSearchForm, false) }} label="top" />
                    <IconButton icon="CaretUp" variant="ghost" size="small" onClick={() => { fetchData(currentSearchForm, 'up') }} label="up"/>
                    <IconButton disabled={pagingTokens.at(-1) === null} icon="CaretDown" variant="ghost" size="small" onClick={() => { fetchData(currentSearchForm, 'down') }} label="down"/>
                    <span style={{opacity: '0.25'}}>page {pagingTokens.length}</span>
                </span>
                <FlowSearchResults searchResults={searchResults} loading={loading} structuredLogsService={structuredLogsService}/>
                <span className="eds-spacing--p-8" style={{display: 'flex', alignItems:'center', justifyContent: 'center'}}>
                    <IconButton icon="CaretLeft" variant="ghost" size="small" onClick={() => { searchHandler(currentSearchForm, false) }} label="top"/>
                    <IconButton icon="CaretUp" variant="ghost" size="small" onClick={() => { fetchData(currentSearchForm, 'up') }} label="up"/>
                    <IconButton disabled={pagingTokens.at(-1) === null} icon="CaretDown" variant="ghost" size="small" onClick={() => { fetchData(currentSearchForm, 'down') }} label="down"/>
                    <span style={{opacity: '0.25'}}>page {pagingTokens.length}</span>
                </span>
            </Box>
        </div>
    )
}

